@charset "UTF-8";

#main-nav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: $white;
  z-index: 10;

  @include maxView($content-width) {
    transition: trans(transform);
    &.mob-hidden {
      transform: translateY(-100%);
    }
  }

  @include minView($content-width + 1) {
    transition: trans(background-color, 400);
    .nav-wrapper {
      transition: transs(height max-height max-width, 400);
    }

    &.reduced {
      background-color: rgba(255,255,255,0.3);

      .nav-wrapper {
        background-color: transparent;
        height: px(60);
        max-height: px(60);
        max-width: px(1320);

        &:hover {
          background-color: $white;
        }
      }
    }
  }



  .nav-wrapper {
    background-color: $white;
    @include flexBoxes(space-between, center, row, nowrap);
    margin: 0 auto;
    width: 100%;
    max-width: px($content-width + 40);
    padding-left: px(20);
    padding-right: px(20);

    @include minView($content-width + 1)  {
      height: px($navFullHeight);
      max-height: px($navFullHeight);
    }

    @include maxView($content-width) {
      height: px($navFullHeight-s);
    }

    .nav-container {
      @include minView($content-width + 1) {
        @include flexBoxes(flex-end, center, row, nowrap);
        height: px(19);
        line-height: px(19);

        .nav {
          @include flexBoxes(space-between, center, row, nowrap);

          li {
            padding-left: px(30);

            &.active {
              a {
                color:$green
              }
            }
          }
        }
      }

      @include maxView($content-width) {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        z-index: -1;
        height: 100vh;
        background-color: $white;
        text-align: center;
        opacity: 0;
        transition: transs(transform opacity);


        .nav {
          @include flexBoxes(center, center, column, nowrap);
          li {
            padding: px(10) 0;
            a {
              padding: px(10) 0;
            }
          }
        }
      }

      .nav {
        li {
          a {
            text-decoration: none;
            transition: trans(color);
            &:hover {
              color: $green;
            }
          }
        }
      }

      .nav-info {
        position: relative;
        display: inline-block;
        padding-left: px(30);

        @include maxView($content-width) {
          margin-top: px(30);
        }

        a {
          color: $green;
          text-decoration-color: transparent;
          transition: trans(text-decoration-color);

          &:hover {
            text-decoration-color: $green;
          }
        }

        &:before {
          content: '';
          position: absolute;
          width: 0;
          left: px(15);
        }

        &.email:before {
          @include minView($content-width + 1) {
            height: px(34);
            top: 50%;
            transform: translateY(-50%);
            border-left: 1px solid #E5E0E0;
          }
        }

        &.tel:before {
          height: 100%;
          top:0;
          border-left: 1px solid $green;
        }
      }
    }
  }

  &.open {
    @include maxView($content-width) {
      .nav-container {
        transform: translateY(100%);
        opacity: 1;
      }

    }
  }
}

#logo {
  width: vw(124);
  max-width: px(124);
}

body {
  @include lView() {
    padding-top: px($navFullHeight);
  }

  @include smView() {
    padding-top: px($navFullHeight-s);
    font-size: px($fontText-s);
  }
}


.menu-btn {
  @include minView($content-width + 1) {
    display: none;
  }

  @include maxView($content-width) {
    position: relative;
    width: px(33);
    height: px(22);

    span {
      position: absolute;
      display: block;
      width: 100%;
      height: px(2);
      background-color: $green;
      transition: transs(opacity transform);
    }

    .t {
      top: 0;
    }

    .m {
      top: px(10);
    }

    .b {
      bottom: 0;
    }
  }
}

.open {
  @include maxView($content-width) {
    .menu-btn {
      .m {
        opacity: 0;
      }

      .t {
        transform: translateY(10px)  rotateZ(-45deg);
      }

      .b {
        transform: translateY(-10px)  rotateZ(45deg);
      }
    }
  }
}
