@charset "UTF-8";
.grid-comp.numbered {
  .grid {
    margin-left: 0;
  }
  counter-set: gridItem;
  .grid-item {
    counter-increment: gridItem;
    position: relative;

    &:before {
      content: counter(gridItem);
      position: absolute;
      top: 0;
      left: 0;
      height: px(20);
      width: px(20);
      background-color: $green;
      color: $white;
      line-height: px(20);
      border-radius: 50%;

      @include sView() {
        font-size: px($fontSmall-s)
      }
      @include mlView() {
        font-size: px($fontSmall)
      }
    }
    .text {
      text-align: left;
    }
  }

}
