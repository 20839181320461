@charset "UTF-8";
$fontName: "OpenSans";
@font-face {
  font-family: '#{$fontName}';
  src: url("../fonts/#{$fontName}-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: '#{$fontName}';
  src: url("../fonts/#{$fontName}-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: $fontName;
  src: url("../fonts/#{$fontName}-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: $fontName;
  src: url("../fonts/#{$fontName}-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: '#{$fontName}';
  src: url("../fonts/#{$fontName}-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: '#{$fontName}';
  src: url("../fonts/#{$fontName}-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: '#{$fontName}';
  src: url("../fonts/#{$fontName}-Bold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: '#{$fontName}';
  src: url("../fonts/#{$fontName}-BoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}
