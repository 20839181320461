@charset "UTF-8";

#main-wrapper {
  position: relative;
  background-color: $white;
  h1 {
    color: $green;
  }

  @include sView() {
    padding-top: vwsmall(100)
  }

  .content-head {
    margin-bottom: px(50);

    .page-lead {
      a {
        color: $green;
      }
    }
  }
}

.green-leaf {
  position: absolute;
  @include mlView() {
    left: 0;
    z-index: -1;
    width: calc(50% + 740px);
    max-width: 100%;
  }

  @include sView() {
    z-index: 1;
  }
  svg {
    max-width: 100%;
    width: 100%;
    height: auto;
  }
}



body#news #main-wrapper {

  .content-head {
    & > .image {
      margin: px(30) 0;
    }
  }
}
