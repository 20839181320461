@charset "UTF-8";

$mobHeight: 300;
.progress-bar-comp {
  position: relative;
  overflow: hidden;
  padding: 0 px(10);
  margin-left: px(-10);
  margin-right: px(-10);
  width: calc(100% + 20px);

  @include maxView($content-width) {
    padding-bottom: px(20);

    .text {
      margin-bottom: px(30);
    }
  }

  .progressbar {
    position: relative;
    @include minView($content-width + 1) {
      height: px(27);
      background: transparent linear-gradient(to right, #DAE8C0 0%, #B0D370 150%) 0% 0% no-repeat padding-box;
    }

    @include maxView($content-width) {
      width: px(27);
      height: px($mobHeight);
      background: transparent linear-gradient(to bottom, #DAE8C0 0%, #B0D370 150%) 0% 0% no-repeat padding-box;
    }

    .progress {
      top: 0;
      left: 0;
      position: absolute;

      @include minView($content-width + 1) {
        background: transparent linear-gradient(to right, #B0D370 0%, #74A220 150%) 0% 0% no-repeat padding-box;
        height: 100% !important;
      }

      @include maxView($content-width) {
        background: transparent linear-gradient(to bottom, #B0D370 0%, #74A220 150%) 0% 0% no-repeat padding-box;
        width: 100% !important;
      }

    }
  }

  .steps {
    @include minView($content-width + 1) {

      @include flexBoxes(space-between, center, row, no-wrap);

      .step {
        position: relative;
        padding-top: px(12);

        span {
          display: block;
          position: relative;
          text-align: center;
          transform: translateX(-50%);
        }

        &:before {
          content: '';
          position: absolute;
          top:0; left: 0;
          border-left: 6px solid transparent;
          border-right: 6px solid transparent;
          border-top: 0 solid transparent;
          border-bottom: 10px solid black;
          transform: translateX(-50%);
        }

        &:first-child {
          span {
            text-align: left;
            transform: translateX(-4px);
          }
        }

        &:last-child {
          span {
            margin-left: px(4);
            text-align: right;
            transform: translateX(-100%);
          }
        }
      }
    }

    @include maxView($content-width) {
      position:absolute;
      left: px(37);
      bottom: px(20);
      height: px($mobHeight);
      @include flexBoxes(space-between, flex-start, column, no-wrap);
      width: calc(100% - 50px) !important;

      .step {
        position: relative;
        padding-left: px(20);
        width: 100% !important;

        span {
          display: block;
          position: absolute;
          bottom:0;
          transform: translateY(9px);
        }

        &:first-child {
          position: absolute;

          span {
            bottom:auto;
            top: 0;
            transform: translateY(-10px);
          }

          &:before {
            top: 0;
            transform: translateY(-5px);
            bottom: auto;
          }
        }

        &:before {
          content: '';
          position: absolute;
          bottom:0; left: 0;
          border-top: 6px solid transparent;
          border-bottom: 6px solid transparent;
          border-left: 0 solid transparent;
          border-right: 10px solid black;
          transform: translateY(5px);
        }
      }
    }
  }
}

