@charset "UTF-8";

$content-width: 1017;

$navFullHeight: 162;
$navFullHeight-s: 84;

$lightGreen: #DAE8C0;
$green: #74A220;
$white: #fff;
$black: #000;
$textColor: #707070;

//fonts
$fontFamily: 'OpenSans', 'sans-serif';
$fontMainTitle: 55;
$fontTitle: 40;
$fontSubtitle: 30;
$fontQuote: 25;
$fontLead: 20;
$fontText: 15;
$fontSmall: 12;


$fontMainTitle-s: 36;
$fontTitle-s: 28;
$fontSubtitle-s: 22;
$fontQuote-s: 22;
$fontLead-s: 20;
$fontText-s: 16;
$fontSmall-s: 15;

//screen widths breakpoints
$screen: (
  "small": 749,
  "medium": 1024,
  "large": 1440
);


//dashed line
//helper: https://kovart.github.io/dashed-border-generator/
$dashedLine: "data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3cline x1='0%25' y1='0%25' x2='100%25' y2='00%25' fill='none' stroke='%2374A220FF' stroke-width='1' stroke-dasharray='4%2c 6' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e";

//speed
$stdSpeed: 200;
$stdBCurve: ease-in-out;
