.fault-hotline {
  position: absolute;
  top: -120px;
  right: 10px;
  padding: 30px 50px;
  text-align: center;
  background-color: #ff5f28;
  color: white;
  border-radius: 60px 60px 60px 60px;
  font-size: 18px;

  @include sView() {
    right: 5px;
    top: -195px;
    padding: 10px 30px;
  }

  @include minView(1100) {
    right: -20px;
  }
}

.fault-hotline-number {
  font-weight: 600;
  font-size: 1.3rem;
  display: block;
}

.fault-hotline-text {
  display: block;
}
