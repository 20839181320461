@charset "UTF-8";

.news-entries {
  .news-entry {
    position: relative;
    .text.image-left {
      .date {
        padding-top: px(11);
      }
    }

    .image, .text {
      a {
        color: $textColor;
        text-decoration: none;
      }

      .link-label {
        color: $green;
        text-decoration: underline;
      }
    }
  }
  @import "./newsEntries/list";
  @import "./newsEntries/slider";
}
