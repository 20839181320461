@charset "UTF-8";
#page-header {
  position: relative;
  background-color: $green;
  overflow: hidden;
  opacity: 0;
  transition: opacity 500ms;
  &.lazy-loaded { opacity: 1}
  &.white-bg {
    background-color: white;
  }
  @include sView() {
    padding-top: px(200);
    font-size: px($fontText-s + 3);
    //background-size: 100% auto;
    //background-position: -50% center;

    &:before {
      content: '';
      background-image: inherit;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: px(200);
      background-size: cover;
      background-position: center center;
    }
  }

  @include mlView() {
    padding-top: px(96);
    background-position: center;
    background-size: cover;
    font-size: px($fontText + 3);
    min-height: px(430);
    padding-bottom: px(166);
    & ~ #main-wrapper {
      margin-top: px(-240);
      min-height: px(240);
    }
  }

  .leaf {
    position: absolute;
    bottom: 0;
    right: calc(-170px + (50vw - 510px));
    transform: translateX(0%);
    height: px(401);
    width: px(285);
    overflow: hidden;
    z-index: 0;
    svg {
      width: 100%;
      max-width: 100%;
      //max-height: 100%;
    }
  }
  h3, .subtitle {
    @include sView() {
      font-size: px($fontSubtitle-s);
    }
    @include mlView() {
      font-size: px($fontSubtitle);
    }
  }

  .fault-hotline {
    top: -80px;
    @include sView() {
      top: -195px;
    }
  }
}

#home {
  #page-header {
    @include mlView() {
      & ~ #main-wrapper {
        margin-top: px(-95);
        min-height: px(100)
      }
    }

    .header-content {
      position: relative;

      @include sView() {
        padding-top: px(39);
        padding-right: px(17);
      }
    }

    .leaf {
      position: absolute;
      bottom: 0;
      right: 0;
      width: vw(322);
      max-width: px(322);
      height: vw(620);
      max-height: px(620);

      svg {
        max-height: 100%;
        max-width: 100%;
      }
    }

    .content {
      position: relative;
      z-index: 1;
      @include mlView() {
        width: 67%
      }

    }
  }
}
