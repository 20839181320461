@charset "UTF-8";


.textImage {
  h2 {
    color: $green;
  }

  .text {
    a, a:visited {
      color: $green;
    }
  }

  @include sView() {
    .image {
      margin-bottom: px(30);
    }
  }
}

$horizontalSidePadding: 20;
$imgWidthPC: 40;

.textImage.horizontal {
  @include mlView() {
    position: relative;
    width: calc(100% + #{px(2 * $horizontalSidePadding)});
    transform: translateX(px(- $horizontalSidePadding));
    @include flexBoxes(flex-start, flex-start, row, nowrap);

    & > div {
      padding-left: px($horizontalSidePadding);
      padding-right: px($horizontalSidePadding);

      &.image {
        width: pc($imgWidthPC);
        padding-top: px(11);
      }

      &.text {
        width: pc(100 - $imgWidthPC);
      }
    }
  }

  @include sView() {
    @include flexBoxes(flex-start, center, column, nowrap);

    .image {
      order: 1;
    }

    .text {
      order: 2;
    }
  }
}
