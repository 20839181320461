@charset "UTF-8";
.grid {
  @include flexBoxes(center, flex-start, row, wrap);
  position: relative;
  margin-left: px(-30);
  margin-right: px(-30);

  .grid-item {
    padding: 0 px(30) px(30) px(30);
    width: 100%;
  }

  &.col-2 {
    .grid-item {
      @include mlView() {
        width: 50%;
      }
    }
  }

  &.col-3 {
    .grid-item {
      @include mlView() {
        width: 33%;
      }
    }
  }

  &.col-4 {
    .grid-item {
      @include mView() {
        width: 50%;
      }
      @include lView() {
        width: 25%;

        .text {
          font-size: px($fontSmall);
          line-height: 1.5;
        }
      }
    }
  }

  &.col-5 {
    .grid-item {
      @include mView() {
        width: 33%;
      }
      @include lView() {
        width: 20%;
      }
    }
  }

  .text-image {
    text-align: center;
    margin-bottom: px(25);
    img {
      height: px(52);
      width: auto;
      margin-bottom: px(10);
    }

    .title {
      color: $green;
      @include mlView() {
        font-size: px($fontSubtitle)
      }

      @include sView() {
        font-size: px($fontSubtitle-s)
      }
    }
  }
}

@import "contacts";
@import "numberedList";
