@charset "UTF-8";

.teaser {
  text-align: center;
  max-width: px(672);
  margin: 0 auto;

  @include mlView() {
    width: 100%;
    padding: px(26) px(45) px(40) px(45);
  }

  @include sView() {
    margin-left: px(-35);
    margin-right: px(-35);
    padding: px(26) px(35) px(40) px(35)
  }

  img {
    width: auto;
    margin-bottom: px(10);
  }

  h3.subtitle {
    margin-bottom: px(15);
    @include mlView() {
      font-size: px($fontSubtitle);
    }

    @include sView() {
      font-size: px($fontSubtitle-s);
    }
  }
}
