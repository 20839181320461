@charset "UTF-8";

.contacts {
  margin-top: px(50);

  a {
    span {
      color: $green;
    }
  }

  .infos {
    text-align: center;
    margin-bottom: px(50);

    a.tel {
      margin-bottom: px(15);
      @include flexBoxes(center, center);
      @include mlView() {
        font-size: px($fontQuote);
      }
      @include sView() {
        font-size: px($fontQuote-s);
      }
      text-decoration: none;

      .icon {
        margin-right: px(7);
        padding-top: px(10);
      }
    }
  }



  .contact {
    text-align: center;
    img {
      max-width: 70%;
      margin-bottom: px(20);
    }

    p {
      margin-bottom: 0;
    }

    .name {
      @include sView() {
        font-size: px($fontQuote-s);
      }
      @include mlView() {
        font-size: px($fontQuote);
      }
    }

    .email {
      text-decoration: none;

      .underlined {
        text-decoration: underline;
      }
    }
  }
}
