@charset "UTF-8";

input {
  border: none;
  background-color: $white;
}

.form {
  background-color: $green;
  color: $white;

  .error {
    cursor: pointer;
  }

  h2 {
    color: $white;
  }

  margin-left: auto;
  margin-right: auto;

  @include mlView() {
    width: 100%;
    max-width: px(672);
    padding: px(30) px(25) px(55) px(30);
  }


  @include sView() {
    position: relative;
    padding: px(30) px(20) px(55) px(30);
    margin-left: px(-35);
    margin-right: px(-35);
  }

  .form-wrapper {

    form {
      input[name=field-name], input[name=field-lastname] {
        display: none;
      }


      input[type=text], input[type=email] {
        height: px(36);
        padding: px(3);

        @include mlView() {
          font-size: px($fontText);
        }

        @include sView() {
          font-size: px($fontText-s);
        }
      }

      @include minView(500) {
        .form-row {
          padding-right: 1%;
        }
        .form-row[data-width=half] {
          padding-right: 0;
          display: inline-block;
          width: 49.5%;

          &:nth-child(2n+1) {
            padding-right: 2%
          }

          &:nth-child(2n) {
            padding-left: 2%
          }
        }
      }

      h3.subtitle {
        margin-bottom: 0;
        margin-top: px(30);
      }

      .form-row {
        margin-top: px(24);

        &.selection ~ .selection {
          margin-top: px(9);
        }

        label {
          display: block;
          margin-bottom: px(6);

        }

        input {
          display: block;
          width: 100%;

        }

        textarea {
          width: 100%;
          min-height: px(120);
          padding: px(7) px(3);
          font-family: $fontFamily;
          @include mlView() {
            font-size: px($fontText);
          }

          @include sView() {
            font-size: px($fontText-s);
          }
        }
      }

      .selection.form-row {
        label.subtitle {
          display: inline-block;
          padding-right: px(25);
        }

        fieldset {
          display: inline-block;

          .form-item {
            position: relative;
            display: inline-block;
            width: auto;

            input {
              margin: 0;
              padding: 0;
            }

            input {
              position: absolute;
              left: 0;
              top: 0;
              width: 20px;
              height: 20px;
              opacity: 0;
            }

            label {
              position: relative;
              padding-left: px(28);
              padding-right: px(30);
              height: px(20);

              &:before, &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: px(20);
                height: px(20);
                box-sizing: border-box;
              }

              &:before {
                background-color: $white;
              }
            }

            input[type=radio] ~ label {
              &:before, &:after {
                border-radius: 50%;
              }
            }

            input ~ label {
              cursor: pointer;

              &:after {
                opacity: 0;
                border: 5px solid $white;
                background-color: $textColor;
                transition: trans(opacity);
              }
            }

            input:checked ~ label:after {
              opacity: 1;
            }


          }
        }
      }

      .button-wrapper {
        margin-top: px(30);
        position: relative;
        display: inline-block;
        left: 50%;
        transform: translateX(-50%);
        background-color: $white;
        font-weight: 600;
        border-radius: 100px;

        input {
          color: $green;
          padding: px(14) px(39);
          @include mlView() {
            font-size: px($fontSmall);
          }
          @include sView() {
            font-size: px($fontSmall-s);
          }
        }

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          background-color: $lightGreen;
          opacity: 0;
          transition: trans(opacity);
          border-radius: px(30);
          mix-blend-mode: multiply;
          pointer-events: none;
        }

        &:hover {
          &:before {
            opacity: 1;
          }
        }
      }
    }
  }
}

