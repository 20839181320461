@charset "UTF-8";

.contact-box {
  .content-width {
    background-color: $white;
  }
}

#footer {
  background-color: #F2F2F2;

  .footer-wrapper {
    padding-top: px(70);
    padding-bottom: px(50);

    h3 {
      text-align: center;
      color: $green;

      @include mlView() {
        font-size: px(43);
      }

      @include sView() {
        font-size: px(28);
      }
    }

    .partners {
      @include mlView() {
        margin-bottom: px(50);
        @include flexBoxes(center, flex-end, row, nowrap);

        li {
          img {
            position: absolute;
            bottom:0;
            max-width: px(148);
            max-height: px(50);
            min-height: px(36);
          }
        }
      }

      @include sView() {
        margin-top: px(40);
        li {
          margin-bottom: px(20);
          text-align: center;
          a {
            margin: 0 auto;
            img {
              max-height: 100%;
            }
          }
        }
      }

      li {
        padding: 0 px(30);
        a, span {
          position: relative;
          display: block;
          height: px(50);
          width: px(148);
        }
      }
    }

    .text {
      text-align: center;
      line-height: 2em;

      @include mlView() {
        font-size: px($fontSmall)
      }

      @include sView() {
        font-size: px($fontSmall-s)
      }

      a {
        color: $green;
      }
    }
  }
}
