@charset "UTF-8";

@for $i from 1 through 5 {
  .space-#{$i} {
    display: block;
    height: #{$i*1.5}em;
  }

  .with-border {
    .space-#{$i} {
      background-image: url($dashedLine);
    }
  }
}



body.editmode {
  $spaceNames: (1: 'small', 2: 'medium', 3: 'large', 4: 'xlarge', 5: 'xxlarge');
  @for $i from 1 through 5 {
    .space-#{$i} {
      position: relative;
      background-color: rgba(155, 194, 102, 0.5);

      &:before {
        content: "#{"Space " + map_get($spaceNames, $i)}";

        position: absolute;
        font-family: Roboto, sans-serif;
        font-size: px(12);
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        color: $white;
      }
    }
  }
}
