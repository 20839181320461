@charset "UTF-8";

body {
  font-family: $fontFamily;
  box-sizing: border-box;
  color: $textColor;
  line-height: 1.35;
  font-weight: 300;

  @include smView() {
    font-size: px($fontText-s);
  }
  @include lView() {
    font-size: px($fontText);
  }

  * {
    box-sizing: border-box;
  }
}

h1, .main-title {
  line-height: 1.2;
  margin-bottom: px(48);

  @include smView() {
    font-size: px($fontMainTitle-s);
  }
  @include lView() {
    font-size: px($fontMainTitle);
  }

  @include maxView(410) {
    font-size: px(30);
  }
}

h2, .title {
  line-height: 1.2;
  margin-bottom: 1em;
  color: $green;
  @include smView() {
    font-size: px($fontTitle-s);
  }
  @include lView() {
    font-size: px($fontTitle);
  }
}

h3, .subtitle {
  line-height: 1.2;
  margin-bottom: 1em;
  @include smView() {
    font-size: px($fontLead-s);
  }
  @include lView() {
    font-size: px($fontLead);
  }
}


p, ul, .button {
  margin-bottom: 1em;
}

.button {
  position: relative;
  display: inline-block;
  padding: px(15) px(22);
  font-weight: 600;
  border-radius: px(30);
  border-width: 1px;
  border-style: solid;
  a {
    text-decoration: none;
    color: $white;
    &:visited, &:focus {
      color: $white;
    }
    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: $lightGreen;
      opacity: 0;
      transition: trans(opacity);
      border-radius: px(30);
      mix-blend-mode: soft-light;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }

  @include smView() {
      font-size: px($fontSmall-s);
  }
  @include lView() {
      font-size: px($fontSmall);
  }

  color: $white;
  border-color: $green;
  background-color: $green;
}



a, a:visited, a:focus {
  color: $textColor;
  outline: none;
}

ul {
  margin: 0;
}

img {
  max-width: 100%;
  width: 100%;
  opacity: 0;
  transition: opacity 200ms ease-in-out;

  &[src] {
    opacity: 1;
  }
}

.content-width {
  width: 100%;
  max-width: px($content-width);
  margin-left: auto;
  margin-right: auto;

  @include sView() {
    padding: px(40) px(35) px(55) px(35);
  }

  @include mlView() {
    padding: px(31) px(45) px(49) px(55);
  }
}

.green-bg, .form {

  background-color: $green;
  color: $white;

  h2, h4 {
    color: $white;
  }

  .button {
    color: $green;
    border-color: $white;
    background-color: $white;

    a, a:visited {
      color: $green;
    }

    a {
      &:before {
        mix-blend-mode: multiply;
      }
    }
  }

  a, a:visited {
    color: $white;
  }
}

h3.quote {
  position: relative;
  padding: px(30);
  text-align: center;
  color: $green;
  margin: px(30) 0;

  @include mlView() {
    font-size: px($fontQuote);
  }

  @include sView() {
    font-size: px($fontQuote-s);
  }


  &:before {
    content: '';
    position: absolute;
    top:px(-1);
    height: px(1);
    width: 100%;
    max-width: px(454);
    left: 50%;
    transform: translateX(-50%);
    background-image: url($dashedLine);
  }

  &:after {
    content: '';
    position: absolute;
    bottom:px(-1);
    height: px(1);
    width: 100%;
    max-width: px(454);
    left: 50%;
    transform: translateX(-50%);
    background-image: url($dashedLine);
  }
}

strong {
  font-weight: bold;
}

.text {
  ul {
    margin-left: px(15);
    li {
      list-style-type: disc;
    }
  }

  ol {
    margin-left: px(15);
    li {
      list-style-type: decimal;
    }
  }
}
