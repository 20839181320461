@charset "UTF-8";

&.slider {
  .swiper-slide {
    padding-bottom: px(30);
  }
  .pagination {
    text-align: center;
    .swiper-pagination-bullet {
      width: px(11);
      height: px(11);
      margin-left: px(12);
      margin-right: px(13);
      background-color: $lightGreen;

      &.swiper-pagination-bullet-active {
        background-color: $green;
      }
    }
  }
}
