.popupContainer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
  justify-content: center;
  align-items: center;
  z-index: 999999;
}

.popupBox {
  position: relative;
  z-index: 9999999;
  background-color: white;
  padding: 40px;
  margin: 10px;
  filter: drop-shadow(0px 0px 10px rgba(0,0,0,0.4));
  border-radius: 10px;
  max-width: 700px;
  min-width: 20vw;
}

.popupBox p {
  margin-bottom: 0;
}

.popupBoxCloseButton {
  position: absolute;
  top: 15px;
  right: 22px;
  font-size: 1.3em;
  cursor: pointer;
}
