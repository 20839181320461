@charset "UTF-8";

.more-news {
  .news-wrapper {
    a {
      text-decoration: none;
    }
    .image {
      position: relative;
      background-color: #F2F2F2;
      background-position: center;
      background-size: cover;
      margin-bottom: px(10);
      .sizer {
        position :relative;
        width: 100%;
        height: 0;
        padding-bottom: 100%;
      }
    }
    @include mlView() {
      @include flexBoxes(flex-start, stretch, row, nowrap);

      .news {
        width: 33%;
        padding: 0 px(10);
      }
    }

    h3 {
      color: $green;
      @include sView() {
        font-size: px($fontSubtitle-s);
      }

      @include mlView() {
        font-size: px($fontSubtitle)
      }
    }
  }
}
