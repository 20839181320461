.textImage table {
  border-collapse: separate;
  border-spacing: 13px;
  width:-webkit-calc(100% + 26px);
  margin: -13px;
}

.textImage tr:first-child {
  font-weight: bold;
}

.textImage td {
  border-bottom: 1px solid #707070;
  padding: 3px;
  padding-left: 0px;
  padding-right: 0px;
  text-align: right;
  vertical-align: bottom;
}

.textImage tr:first-child td {
  border-bottom: 0.15em solid #707070;
}

td:first-child {
  width: 70%;
  text-align: left;
}
